import React from "react"
import { Link } from "gatsby"

const BlogItem = props => {
  var publishedDate = new Date(props.publishedDate.seconds * 1000)
  var options = { dateStyle: "long" }
  return (
    <>
      <div className="row blog-item mb4">
        <div className="medium-6 columns mb6 matchheight-me">
          <div className="blog-item__image">
            <Link to={`/about-us/blog${props.slug}/`}>
              <img src={props.imageUrl} alt={props.title} />
            </Link>
          </div>
        </div>
        <div className="medium-6 columns matchheight-me">
          <h2 className="headings xshort regular">{props.title}</h2>
          <div className="blog-item__details">
            <span className="blog-item__details-date">
              {publishedDate.toLocaleDateString("en-US", options)}
            </span>
            <span className="blog-item__details-options" />
          </div>
          <p className="blog-excerpt"></p>
          <p className="mt6">
            <Link
              to={`/about-us/blog${props.slug}/`}
              className="button secondary tiny wide caps bold"
            >
              Read More
            </Link>
          </p>
          <p className="blog-share m0">
            {/* <span className="share-title">
              SHARE <span className="icon-angle-double-right"></span>
            </span>{" "} */}
          </p>
          <div
            className="addthis_inline_share_toolbox"
            // addthis:url="https://www.ssllc.com/blog/28th-annual-ispe-boston-product-show"
            //addthis:title="28th Annual ISPE Boston Product Show"
            //data-url="https://www.ssllc.com/blog/28th-annual-ispe-boston-product-show"
            //data-title="28th Annual ISPE Boston Product Show"
          ></div>
        </div>
      </div>
    </>
  )
}

export default BlogItem
